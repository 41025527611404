var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 问题分类 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-button',{staticClass:"all_boder_btn",on:{"click":_vm.editFilling}},[_vm._v("新建")])]},proxy:true}])}),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.cpId; },"columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
      total: _vm.total,
      current: _vm.pageNumber,
      defaultPageSize: _vm.pageSize,
      showSizeChanger: true,
      showTotal: function (total, range) {
        return ("共" + total + "条");
      },
    }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + index + 1)+" ")]}},{key:"operation",fn:function(item, row, index){return [_c('span',{staticClass:"blueText"},[(!(_vm.pageNumber == 1 && index === 0))?_c('span',{on:{"click":function($event){return _vm.moveup(row.cpId)}}},[_vm._v("上移 |")]):_vm._e(),(!((_vm.total % _vm.pageSize) - 1 == index))?_c('span',{on:{"click":function($event){return _vm.movedown(row.cpId)}}},[_vm._v("下移 |")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.editItem(row)}}},[_vm._v("编辑")]),_vm._v(" | "),_c('span',{on:{"click":function($event){return _vm.delItem(row.cpId)}}},[_vm._v("删除")])])]}}])}),_c('a-modal',{attrs:{"width":"500px","title":"新建分类","centered":true,"closable":false},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c('div',{staticClass:"all_content_box"},[_c('div',{staticClass:"L_R_inner"},[_c('span',{staticClass:"all_left_name all_required"},[_vm._v("分类名称：")]),_c('div',{staticClass:"right_Div"},[_c('a-input',{staticClass:"all_input",attrs:{"placeholder":"请输入分类名称"},model:{value:(_vm.sortObj.categoryName),callback:function ($$v) {_vm.$set(_vm.sortObj, "categoryName", $$v)},expression:"sortObj.categoryName"}})],1)]),_c('div',{staticClass:"L_R_inner"},[_c('span',{staticClass:"all_left_name"},[_vm._v("分类描述：")]),_c('div',{staticClass:"right_Div"},[_c('a-textarea',{staticStyle:{"resize":"none"},attrs:{"placeholder":"请简单对分类进行描述","rows":4},model:{value:(_vm.sortObj.info),callback:function ($$v) {_vm.$set(_vm.sortObj, "info", $$v)},expression:"sortObj.info"}})],1)])]),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.PreventLoad},on:{"click":_vm.editOk}},[_vm._v("确认")]),_c('a-button',{on:{"click":_vm.editCancel}},[_vm._v("取消")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }